import axios from 'axios';

interface PaymentData {
  full_name: string;
  total_price: string;
  requisite: string;
  to_be_paid: string;
  user: number;
}


export const createPayment = async (paymentData: PaymentData): Promise<any> => {
  try {
    const response = await axios.post<PaymentResponse>('https://admin.meedcoin.com/api/frontend/payment/', paymentData, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    });

    console.log('Payment created successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error creating payment:', error);
    throw error;
  }
};
