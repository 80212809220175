import { HourglassIcon, MedalIcon, PaymentIcon, RocketIcon } from "../../../../assets";


export const AdvantagesConfig = [
    {
      img: MedalIcon ,
      title: 'Earn the way you want',
      description: 'Use an "EA", hold during news, hold trades over the weekend and trade lot sizes as big as the leverage allows, all while adhering to our trading rules',
    },
    {
        img: HourglassIcon,
        title: 'Unlimited Trading Days',
        description: 'We understand that putting a time limit creates a stressful environment, therefore we have no maximum limit on trading days. Take all the time you need.',
    },
    {
        img:RocketIcon ,
        title: 'Scaling Plan' ,
        description: "Scale your way up with us. We increase the account size, Maximum Loss, and Maximum Daily Loss to a 14% Maximum Loss and a 7% Daily Loss limit. Once the trader receives a 'Hot Seat' at the firm, they can trade up to $2 million with on-demand payouts and a monthly bonus. Read more about our Scaling Plan.",
    },
    {
      img: PaymentIcon ,
      title: 'Payout System' ,
      description: 'Request a payout whenever it suits you. Once you become our client, you receive payouts on demand with 100% profit sharing.',
    },
  ];
  