import axios from 'axios';

export const createSupportTicket = async (data: SupportTicketRequest) => {
  try {
    const response = await axios.post('https://admin.meedcoin.com/api/frontend/support-ticket/', data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    console.log('Support ticket created successfully:', response.data);
  } catch (error) {
    console.error('Error creating support ticket:', error);
  }
};

// Пример типа данных для запроса
interface SupportTicketRequest {
  full_name: string;
  email: string;
  account_number: string;
  subject: string;
  description: string;
}
