import {
    BinanceIcon,
    CoinbaseExchangeIcon,
    BybitIcon,
    OKXIcon,
    UpbitIcon,
    HTXIcon,
    KrakenIcon,
    GateIoIcon,
    BitfinexIcon,
    KuCoinIcon,
    MEXCIcon,
    BitgetIcon,
    CryptoComExchangeIcon,
    BinanceTRIcon,
    BingXIcon
} from "../../../../assets";

export const exchanges = [
    { name: "Binance", logo: BinanceIcon },
    { name: "Coinbase Exchange", logo: CoinbaseExchangeIcon },
    { name: "Bybit", logo: BybitIcon },
    { name: "OKX", logo: OKXIcon },
    { name: "Upbit", logo: UpbitIcon },
    { name: "HTX", logo: HTXIcon },
    { name: "Kraken", logo: KrakenIcon },
    { name: "Gate.io", logo: GateIoIcon },
    { name: "Bitfinex", logo: BitfinexIcon },
    { name: "KuCoin", logo: KuCoinIcon },
    { name: "MEXC", logo: MEXCIcon },
    { name: "Bitget", logo: BitgetIcon },
    { name: "Crypto.com Exchange", logo: CryptoComExchangeIcon },
    { name: "Binance TR", logo: BinanceTRIcon },
    { name: "BingX", logo: BingXIcon }
];
