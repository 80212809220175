import axios from 'axios';

export interface Requisite {
  id?: number;
  title: string;
  icon: string;
  show?: boolean;
  client?: number;
}

// Получение списка requisites
export const fetchRequisites = async () => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response = await axios.get('https://admin.meedcoin.com/api/frontend/requisites/', {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
    });

    console.log('Requisites fetched successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching requisites:', error);
    throw error;
  }
};

// Создание или обновление requisite в зависимости от наличия id
export const saveRequisite = async (data: Requisite) => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const url = data?.id
      ? `https://admin.meedcoin.com/api/frontend/requisites/${data?.id}/`
      : 'https://admin.meedcoin.com/api/frontend/requisites/';
    const method = data?.id ? 'put' : 'post';

    const response = await axios({
      method,
      url,
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
      data,
    });

    console.log(`Requisite ${data?.id ? 'updated' : 'created'} successfully:`, response.data);
    alert('Data has been successfully updated!')
    return response.data;
  } catch (error) {
    console.error(`Error ${data?.id ? 'updating' : 'creating'} requisite:`, error);
    throw error;
  }
};
