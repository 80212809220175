import HomePage from '../../pages/HomePage';
import BlogPage from '../../pages/BlogPage';
import FAQPage from '../../pages/FAQPage';
import ContactPage from '../../pages/ContactPage';
import PrivacyPolicyPage from '../../pages/PrivacyPolicyPage';
import TermsConditionsPage from '../../pages/TermsConditionsPage';
import ChooseYourPlanPage from '../../pages/ChooseYourPlanPage';

import SatellitesPage from '../../pages/SatellitesPage';
import ProfilePage from '../../pages/ProfilePage';
import { SettingsPage } from '../../pages/SettingsPage';
import WithdrawalRequisitiesPage from '../../pages/WithdrawalRequisitiesPage';
import TradeHistoryPage from '../../pages/TradeHistoryPage';
import RefillPage from '../../pages/RefillPage';
import WithdrawPage from '../../pages/WithdrawPage';


export const ROUTES = {
  HOME: '/',
  BLOG: '/blog',
  FAQ: '/FAQ',
  CONTACT: '/contact',
  PRIVACY_POLICY: '/privacy-policy',
  TERMS_CONDITIONS: '/terms-conditions',
  CHOOSE_YOUR_PLAN: '/choose-your-plan',

  PROFILE: '/profile',
  SATELLITES: '/satellites',
  SETTINGS: '/settings',
  SETTINGS_PERSONAL_INFO: '/settings/personal-info',
  SETTINGS_CHANGE_PASSWORD: '/settings/change-password',
  WITHDRAWAL_REQUISITIES: '/requisities',
  TRADE_HISTORY: '/trade-history',
  REFILL: '/refill',
  WITHDRAW: '/withdraw',

};
export const routesConfig = [
  {
    path: ROUTES.HOME,
    component: HomePage,
    exact: true,
  },
  {
    path: ROUTES.BLOG,
    component: BlogPage,
  },
  {
    path: ROUTES.FAQ,
    component: FAQPage,
  },
  {
    path: ROUTES.CONTACT,
    component: ContactPage,
  },
  {
    path: ROUTES.PRIVACY_POLICY,
    component: PrivacyPolicyPage,
  },
  {
    path: ROUTES.TERMS_CONDITIONS,
    component: TermsConditionsPage,
  },
  {
    path: ROUTES.CHOOSE_YOUR_PLAN,
    component: ChooseYourPlanPage,
  },
  {
    path: ROUTES.PROFILE,
    component: ProfilePage,
  },
  {
    path: ROUTES.SATELLITES,
    component: SatellitesPage,
  },
  {
    path: ROUTES.SETTINGS,
    component: SettingsPage,
  },
  {
    path: ROUTES.SETTINGS_PERSONAL_INFO,
    component: SettingsPage,
  },
  {
    path: ROUTES.SETTINGS_CHANGE_PASSWORD,
    component: SettingsPage,
  },
  {
    path: ROUTES.WITHDRAWAL_REQUISITIES,
    component: WithdrawalRequisitiesPage,
  },
  {
    path: ROUTES.TRADE_HISTORY,
    component: TradeHistoryPage,
  },
  {
    path: ROUTES.REFILL,
    component: RefillPage,
  },
  {
    path: ROUTES.WITHDRAW,
    component: WithdrawPage,
  },
];
