import axios from 'axios';
import { IResponse } from './axiosClient';
import { fetchSatellites } from './satellites';


interface RegisterRequest {
  username: string;
  full_name: string;
  phone: string;
  email: string;
  country: string;
  password_visible: string;
}

export async function registerClient(data: RegisterRequest): Promise<IResponse> {
  try {
    const response = await axios.post('https://admin.meedcoin.com/api/frontend/client/register/', data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return {data: response.data, error: null}
  } catch (error) {
    return {data: null, error: error}
  }
};


export async function authenticateUser(username: string, password: string): Promise<IResponse > {
  const requestData = {
    username: username,
    password: password,
  };

  try {
    const response = await axios.post('https://admin.meedcoin.com/api/frontend/token/client', requestData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });


    const {access, refresh} = response.data;
    localStorage.setItem('accessToken', access);
    localStorage.setItem('refreshToken', refresh);

    return await fetchSatellites().then(responseSatellite => {

      if (responseSatellite.data) {
        return {data: response, error: null}
      } else {
        return {data: null, error: responseSatellite.error}
      }

    })


    // return {data: response, error: null}
  } catch (error) {
    return {data: null, error: error}
  }
}

