import React from 'react';
import './styles.scss'

type Props = {

}
export default function FAQSidebar(props:Props){
  return (

    <div className='faq-sidebar'>
      <div className='faq-sidebar-wrapper'>
      <div className='faq-sidebar-container'>
      <h4>Blockchain Fundamentals</h4>
      <ul>
        <li><a href="#anchor1">What is a blockchain?</a></li>
        <li><a href="#anchor2">What is blockchain software?</a></li>
        <li><a href="#anchor3">What is a blockchain database?</a></li>
        <li><a href="#anchor4">What is a blockchain system?</a></li>
        <li><a href="#anchor6">What is a blockchain application?</a></li>
        <li><a href="#anchor7">What are the benefits of blockchain technology?</a></li>
        <li><a href="#anchor8">What is the blockchain revolution??</a></li>
        <li><a href="#anchor9">What is decentralized finance (DeFi)?</a></li>
        <li><a href="#anchor10">What is a block in a blockchain?</a></li>
        <li><a href="#anchor11">What is block time?</a></li>
        <li><a href="#anchor12">What is distributed ledger technology?</a></li>
        <li><a href="#anchor13">What is a blockchain wallet?</a></li>
        <li><a href="#anchor14">What is blockchain programming?</a></li>
        <li><a href="#anchor15">What is a blockchain company?</a></li>
        <li><a href="#anchor16">What is a private blockchain?</a></li>
        <li><a href="#anchor17">What are zk-SNARKs?</a></li>
      </ul>
      <h4>Ethereum Fundamentals</h4>
      <ul >
        <li><a href="#anchor18">What is Ethereum?</a></li>
        <li><a href="#anchor19">How does Ethereum work?</a></li>
        <li><a href="#anchor20">What is an Ethereum block?</a></li>
        <li><a href="#anchor21">What is an Ethereum smart contract?</a></li>
        <li><a href="#anchor22">What is an Ethereum client?</a></li>
        <li><a href="#anchor23">What is Ethereum backed by?</a></li>
        <li><a href="#anchor24">What is ether (ETH)?</a></li>
        <li><a href="#anchor25">What is an ETH address?</a></li>
        <li><a href="#anchor26">How can I earn ether (ETH)?</a></li>
        <li><a href="#anchor27">How long does it take to transfer ether?</a></li>
        <li><a href="#anchor28">What is the gas limit in Ethereum?</a></li>
        <li><a href="#anchor29">What is Ethereum coded in?</a></li>
        <li><a href="#anchor30">What is Ethereum used for?</a></li>
        <li><a href="#anchor31">What is the Enterprise Ethereum Alliance?</a></li>
      </ul>
      <h4>Bitcoin vs. Ethereum</h4>
      <ul >
        <li><a href="#anchor32">What’s the difference between Bitcoin and Ethereum?</a></li>
        <li><a href="#anchor33">What’s better, Bitcoin or Ethereum?</a></li>
      </ul>
      <h4>Ethereum Roadmap</h4>
      <ul>
        <li><a href="#anchor34">What is Byzantium Ethereum?</a></li>
        <li><a href="#anchor35">What is Proof of Stake (PoS) Ethereum?</a></li>
        <li><a href="#anchor36">What’s next for Ethereum?</a></li>
      </ul>
      <h4>Ethereum Mining</h4>
      <ul>
        <li><a href="#anchor37">What is Ethereum mining?</a></li>
        <li><a href="#anchor38">How long does it take to mine Ethereum?</a></li>
        <li><a href="#anchor39">What is the hashrate of Ethereum?</a></li>
        <li><a href="#anchor40">Can I mine Ethereum?</a></li>
        <li><a href="#anchor41">Can my computer mine Ethereum?</a></li>
        <li><a href="#anchor42">What is Ethereum difficulty?</a></li>
        <li><a href="#anchor43">What do you need to mine Ethereum?</a></li>
        <li><a href="#anchor44">Can you mine Ethereum on a laptop?</a></li>
        <li><a href="#anchor45">Can you mine Ethereum on a Mac?</a></li>
        <li><a href="#anchor46">How much Ethereum can I mine in a day?</a></li>
      </ul>
    </div>
    </div>
    </div>
  )
}
