export const firstTableData = [
  ['', 'Student', 'Practitioner'],
  ['Trading Period', '∞', '∞'],
  ['Minimum Trading Days', '3 days', '1 day'],
  ['Maximum Daily Loss', '5%', '5%'],
  ['Maximum Loss', '10%', '10%'],
  ['Profit Target', '$8.000 (8%)', '$5.000 (5%)'],
  ['Leverage', '1:100', '1:100']
]

export const secondTableData = [
  ['', 'Master'],
  ['Trading Period', '∞'],
  ['Maximum Daily Loss', '5%'],
  ['Maximum Loss', '10%'],
  ['Profit Target', '-'],
  ['Leverage', '1:100'],
  ['Payouts & Profit Split', 'Flexible'],
]
