import React, { useCallback, useEffect, useState } from 'react';
import './styles.scss';
import { CloseToggleSidebarIcon, LogoutIcon, OpenToggleSidebarIcon } from '../../assets';
import { sideBarParams } from './const';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../Navigation';

import { useTheme } from '../../provider/ThemeProvider';

type ISidebarItem = {
  icon: React.FC<any>;
  isActive: boolean;
  drawerOpen: boolean;
  title: string;
  onClick: () => void;

}

const SidebarItem: React.FC<ISidebarItem> = (props) => {
  return (
    <div className={`sidebar-item ${props.isActive && 'active'}`} onClick={props.onClick}>
      <div className="icon">
        <props.icon color={props.isActive ? '#1A56DB' : '#4B5563'}/>
      </div>
      {props.drawerOpen && <p>{props.title}</p>}
    </div>
  );
};
type Props = {
  isBurger?: boolean;
}
const Sidebar: React.FC<Props> = (props: Props) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [list, setList] = useState(sideBarParams);
  const onHandleClick = useCallback((id: string) => {
    const currentItem: any = list.find((item: any) => item.id === id)

    if (currentItem) {

      navigate(currentItem.path)
    }


  }, [])
  useEffect(() => {
    setList(prevState => prevState.map((item: any) => {
      return location.pathname.includes(item.path) || location.pathname.includes(item?.path2)
        ? {...item, isActive: true}
        : {...item, isActive: false};
    }));
  }, [location.pathname]);
  const handleShowDrawer = () => {
    setDrawerOpen(prevState => !prevState)
  }

  const handleLogout = () => {
    navigate(ROUTES.HOME)
  }

  const {theme} = useTheme()
  const isDarkTheme = theme === 'dark'
  return (
    <div className="dark-sidebar">
      <nav className={`sidebar ${drawerOpen ? 'open' : 'closed'} ${props.isBurger && 'isBurger'} `}>
        {!props.isBurger && <div className="sidebar-header">
          {drawerOpen && <h2>Menu</h2>}
            <div onClick={handleShowDrawer} className="pointer">
              {drawerOpen ? <OpenToggleSidebarIcon/> : <CloseToggleSidebarIcon/>}
            </div>
        </div>}
        <div className="sidebar-list">
          {list.map((item: any, index) => {
            if (item.id === 'divider') return <span key={index} className="divider"/>
            return <SidebarItem key={index} icon={item.icon} isActive={item.isActive} title={item.text}
                                onClick={() => onHandleClick(item.id)} drawerOpen={drawerOpen}/>
          })}
        </div>
        <div className="sidebar-footer pointer" onClick={handleLogout}>
          <div>
            <LogoutIcon/>
          </div>
          {drawerOpen && <p>Log out</p>}
        </div>
      </nav>
    </div>
  );
};

export default Sidebar;
