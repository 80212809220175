import React, { useEffect, useState } from 'react';
import './styles.scss'
import WrapperPage from '../../components/WrapperPage';
import Input from '../../components/UI/Input';
import { VisaImg } from '../../assets';
import Button, { EButtonType } from '../../components/UI/Button';
import { useTheme } from '../../provider/ThemeProvider';
import Header from '../../components/Header';
import { fetchRequisites, saveRequisite } from '../../api/requisite';

type Props = {}

export default function WithdrawalRequisitiesPage(props: Props) {
  const [newRequisites, setNewRequisites] = useState<string | undefined>(undefined);
  const [requisites, setRequisites] = useState<any | null>(null);
  const [error, setError] = useState<string | null>(null); // Добавляем состояние для ошибки

  const onHandleEditProfile = async () => {
    try {
      // Здесь добавляем запрос на сохранение данных, обработка которого будет ниже
      // Например, вызов saveRequisites(newRequisites), который может вернуть ошибку
      // await saveRequisites(newRequisites);
      console.log('Детали отправлены',requisites);
      console.log(requisites,333)
      saveRequisite({
        id: requisites?.id || null,
        title: newRequisites as string || ' ',
        icon: '1',
      }).catch((error:any)=>{
        setError('You cannot submit an empty value.')
      })
    } catch (err: any) {
      // Обрабатываем ошибку и сохраняем сообщение об ошибке
      setError(err.message || 'Не удалось отправить данные');
    }
  };

  const onHandleChange = (value: any) => {
    setNewRequisites(value.target.value);
    setError(null); // Сбрасываем ошибку при изменении инпута
  };

  useEffect(() => {
    fetchRequisites()
      .then(response => {
        if (response && response[0].title) {
          setRequisites(response[0]);
          setNewRequisites(response[0].title);
        }
      })
      .catch(err => {
        setError(err.message || 'Не удалось загрузить реквизиты');
      });
  }, []);

  const {theme} = useTheme();
  const isDarkTheme = theme === 'dark';

  return (
    <div className={`withdrawal-page withdrawal-requisities-page-wrapper ${isDarkTheme ? 'dark-theme' : ''}`}>
      <div className="hide-on-mobile">
        <Header disableContainer isAuth/>
      </div>
      <WrapperPage>
        <div className="withdrawal-page withdrawal-requisities-page">
          <h2>Withdrawal requisites</h2>
          <div className="form">
            {/*<img src={VisaImg} alt="PeesIcon" height={80}/>*/}
            <Input
              placeholder="0000 0000 0000 0000"
              value={newRequisites}
              onChange={onHandleChange}
              errorMessage={error}
            />
            <Button
              label="Send the details"
              onClick={onHandleEditProfile}
              fullWidth
              variant={EButtonType.BUTTON_PRIMARY}
            />
          </div>
        </div>
      </WrapperPage>
    </div>
  );
}
