import axios from 'axios';
import { IResponse } from './axiosClient';

export const fetchSatellites = async () => {
  try {
    const accessToken = localStorage.getItem('accessToken')
    const response = await axios.get('https://admin.meedcoin.com/api/frontend/client/1/', {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
    });

    return {data: response.data, error: null}
  } catch (error) {
    console.error('Error fetching news:', error);
    return {data: null, error: error}
  }
};


interface Tokens {
  refresh: string;
  access: string;
}


export interface Satellite {
  id: number;
  uuid: string;
  username: string;
  name: string | null;
  city: string | null;
  country: string | null;
  address: string | null;
  last_name: string | null;
  born: string | null;
  phone: string | null;
  email: string | null;
  invitation_code: string;
  allow_password_update: boolean;
  block_balance: number;
  active_balance: number;
  withdrawal: number;
  tokens: Tokens;
}


export const fetchSatelliteById = async (id: number): Promise<Satellite> => {

  localStorage.setItem('loginId', String(id))
  try {
    const accessToken = localStorage.getItem('accessToken')

    if (!accessToken) {
      throw new Error('Token not found');
    }

    const response = await axios.get<Satellite>(`https://admin.meedcoin.com/api/frontend/satellite/${id}/`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
    });

    localStorage.setItem('satellite', JSON.stringify(response.data))
    return response.data;
  } catch (error) {
    console.error('Error fetching satellite data:', error);
    throw error;
  }
};


interface ProfileData {
  uuid?: string;
  username?: string;
  last_name?: string;
  name?: string;
  phone?: string;
  country?: string;
  city?: string;
  address?: string;
  born?: string;
  email?: string;
}

export const updateSatelliteById = async (id: number, data: Partial<ProfileData>) => {
  try {
    const accessToken = localStorage.getItem('accessToken')
    const response = await axios.patch(`https://admin.meedcoin.com/api/frontend/satellite/${id}/`, data, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
    });
    alert('Data has been successfully updated!')
    return response.data;
  } catch (error) {
    console.error('Error updating profile:', error);
    throw error;
  }
};


export const updateSatellitePasswordById = async (id: number, data: {
  old_password: string,
  new_password: string,
  new_password2: string
}) => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response = await axios.patch(`https://admin.meedcoin.com/api/frontend/satellite/password/${id}/`, data, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
    });
    alert('Password has been successfully updated!')
    return response.data;
  } catch (error) {
    console.error('Error updating password:', error);
    throw error;
  }
};


export const getSatelliteToken = async (data: {
  uuid: string,
  username: string,
  password: string
}): Promise<IResponse> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response = await axios.post('https://admin.meedcoin.com/api/frontend/token/satellite/', data, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
    });
    return {data: response, error: null}
  } catch (error) {
    return {data: null, error: error}
  }
};
