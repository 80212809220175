import React, { useEffect, useState } from 'react';
import './styles.scss'
import WrapperPage from '../../components/WrapperPage';
import Input from '../../components/UI/Input';
import Button from '../../components/UI/Button';
import { getSatellite, saveSatellite } from '../../utils/getDataFromLocalStore/satellite';
import { updateSatelliteById } from '../../api/satellites';
import { useTheme } from '../../provider/ThemeProvider';
import Header from '../../components/Header';


import { MoonIcon } from '../../../src/assets';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../components/Navigation';
import CountrySelect from '../../components/UI/CountrySelect';

{/* TODO */
}

type Props = {}
export default function ProfilePage(props: Props) {
  const navigate = useNavigate()
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [address, setAddress] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    const satellite = getSatellite();
    if (satellite) {
      satellite.country && setCountry(satellite.country)
      satellite.city && setCity(satellite.city)
      satellite.born && setBirthDate(satellite.born)
      satellite.address && setAddress(satellite.address)
      satellite.phone && setPhoneNumber(satellite.phone)
      satellite.email && setEmail(satellite.email)
    }

    console.log(satellite);
  }, []);

  const onHandleEditProfile = () => {
    // const satelliteData = {
    //   country,
    //   city,
    //   born: birthDate,
    //   address,
    //   phone: phoneNumber,
    //   email
    // }
    // const satelliteId = localStorage.getItem('loginId')
    // if (satelliteId) {
    //   updateSatelliteById(+satelliteId, satelliteData).then(data => {
    //     data && saveSatellite(data)
    //   })
    // }
    navigate(ROUTES.SETTINGS_PERSONAL_INFO)
  };


  {/* TODO */
  }
  type Props = {}
  const {toggleTheme} = useTheme()

  function handleChangeTheme() {
    toggleTheme()
  }

  {/* TODO */
  }


  const {theme} = useTheme()
  const isDarkTheme = theme === 'dark'

  return (

    <div className="profile-page-wrapper">
      <div className="hide-on-mobile">
        <Header disableContainer isAuth/>
      </div>
      <WrapperPage>


        <div className="profile-page">

          <h2>Profile</h2>
          <div className="profile-form">
            <CountrySelect

              disabled
              placeholder="Country"
              value={country}
              onCountrySelect={() => {}}/>
            <Input
              disabled
              placeholder="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
            <Input
              disabled
              placeholder="Birth date"
              value={birthDate}
              onChange={(e) => setBirthDate(e.target.value)}
            />
            <Input
              disabled
              placeholder="Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
            <Input
              disabled
              placeholder="Phone number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <Input
              disabled
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button label="Edit profile" onClick={onHandleEditProfile} fullWidth/>
          </div>
        </div>

      </WrapperPage>
    </div>
  );
}
