import React from 'react';
import './styles.scss'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { routesConfig } from './const';

type Props = {
  children?: React.ReactNode;
}
export default function Navigation(props: Props) {
  return (
    <BrowserRouter>
      {props.children}
      <Routes>
        {routesConfig.map(route => (
          <Route
            key={route.path} path={route.path} element={<route.component/>}/>
        ))}
      </Routes>
    </BrowserRouter>
  )
}
export { ROUTES } from './const';
