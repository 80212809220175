import axios from 'axios';

export const fetchPaymentPlan = async (name:string) => {
  try {
    const response = await axios.get('https://admin.meedcoin.com/api/frontend/payment-plan/', {
      params: { name },
      headers: {
        'Accept': 'application/json',
      },
    });

    console.log('Payment plan fetched successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching payment plan:', error);
    throw error;
  }
};
