export const NavConfig = [
    {
      name: 'Contact us' ,
      path: "/contact"
    },
    {
        name: 'Privacy Policy' ,
        path: "/privacy-policy"
    },
    {
        name: 'Terms & Conditions',
        path: "/terms-conditions"
    },
    {
        name:'Home' ,
        path:"/"
    },
    {
        name: 'FAQ',
        path: "/FAQ"
    },
  ];
