import axios from 'axios';

export const fetchTransactions = async (type: string) => {
  try {
    const accessToken = localStorage.getItem('accessToken')
    const response = await axios.get('https://admin.meedcoin.com/api/frontend/transaction/', {
      params: {type},
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
    });

    console.log('Transactions fetched successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching transactions:', error);
    throw error;
  }
};
