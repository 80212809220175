import React from 'react';
import Navigation from './components/Navigation';
import { ModalProvider } from './provider/ModalContext';
import { useTheme } from './provider/ThemeProvider';

function App() {
  const {theme} = useTheme()
  return (
    <div className={theme}>
      <ModalProvider>
        <Navigation/>
      </ModalProvider>
    </div>
  );
}

export default App;
